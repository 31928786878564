<template>
  <div class="text-center">
    <span class="span-link" v-on:click="navigateHome">
      <img
        title="WordLister"
        :src="require(`@/assets/scrabble_tiles_photo_cropped_and_resized.png`)"
        alt="WordLister"
        style="width:90%"
      />
    </span>
  </div>
  <!--
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a href="/" class="navbar-brand">WordLister&nbsp;.org</a>

      <div class="collapse navbar-collapse" id="navbarColor02"></div>
    </nav>
-->
</template>

<script>
export default {
  methods: {
    navigateHome() {
      if (this.$route.path === "/") {
        // do nothing
      } else {
        this.$router.push({
          name: "home"
        });
      }
    }
  }
};
</script>

<style scoped>
.span-link {
  cursor: pointer;
  color: black;
}

#nav {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px solid grey;
  z-index: 1;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
  padding: 0 10px;
  text-decoration: none;
  font-weight: bold;
}

.nav-links {
  display: flex;
}
.links {
  padding-right: 20px;
  list-style: none;
}
.links:hover {
  text-decoration: underline;
}
.logo {
  font-size: 20px;
  color: purple;
  font-weight: bold;
}
</style>
