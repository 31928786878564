<template>
  <div id="app" class="container">
    <TheNavigation />
    <router-view :key="$route.path" />
    <Footer />
  </div>
</template>

<script>
import TheNavigation from "@/components/TheNavigation";
import Footer from "@/components/Footer";

export default {
  name: "app",
  components: {
    TheNavigation,
    Footer
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
