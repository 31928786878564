import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
    props: true
  },
  {
    path: "/privacynotice",
    name: "privacynotice",
    component: () =>
      import(/* webpackChunkName: "PrivacyNotice" */ "../views/PrivacyNotice")
  },
  {
    path: "/Qwords",
    name: "Qwords",
    component: () => import(/* webpackChunkName: "Qwords" */ "../views/Qwords")
  },
  {
    path: "/ZWords",
    name: "ZWords",
    component: () => import(/* webpackChunkName: "ZWords" */ "../views/ZWords")
  },
  {
    path: "/JWords",
    name: "JWords",
    component: () => import(/* webpackChunkName: "JWords" */ "../views/JWords")
  },
  {
    path: "/TwoLetterWords",
    name: "TwoLetterWords",
    component: () =>
      import(/* webpackChunkName: "TwoLetterWords" */ "../views/TwoLetterWords")
  },
  {
    path: "/ThreeLetterWords",
    name: "ThreeLetterWords",
    component: () =>
      import(
        /* webpackChunkName: "ThreeLetterWords" */ "../views/ThreeLetterWords"
      )
  },
  {
    path: "/definition/:word",
    name: "definition",
    component: () =>
      import(/* webpackChunkName: "Definition" */ "../views/Definition"),
    props: true
  },
  {
    path: "*",
    name: "notfound",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
