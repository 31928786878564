<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-5">
          <div class="footer-widget">
            <router-link to="/">
              <h3>WordLister.org</h3>
            </router-link>
            <div class="footer-widget-content">
              © 2020 |
              <a
                href="mailto:wordlister.email@gmail.com?subject=Advertising On wordlister"
                class="contact-link"
                >Advertise |</a
              >
              <a
                href="mailto:wordlister.email@gmail.com?subject=Feedback about wordlister"
                class="contact-link red"
              >
                Feedback |
              </a>
              <a
                href="mailto:wordlister.email@gmail.com?subject=Contact wordlister"
                class="contact-link red"
              >
                Contact |
              </a>

              <router-link to="privacynotice" rel="privacy">
                Privacy</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div></div>
        </div>
        <div class="col-sm-3">
          <div class="footer-widget"></div>
        </div>

        <div class="col-sm-3">
          <div></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped></style>
